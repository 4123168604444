import React from "react";
import Img from "gatsby-image";
import { Link, graphql } from "gatsby";
import { css } from "@emotion/core";
import Layout from "../components/layout";
import Header from "../components/header";
import Helmet from "react-helmet";
import SEO from "../components/SEO";
import config from "../utils/siteConfig";
import posed from "react-pose";

const transition = {
  duration: 350
};

const Feature = posed.div({
  hoverable: true
});

const ImgWrap = posed.div({
  init: {
    scale: 1,
    transition
  },
  hover: {
    scale: 1.15,
    transition
  }
});

const Title = posed.div({
  init: {
    x: 0,
    y: -6,
    transition
  },
  hover: {
    x: 40,
    y: -4,
    transition
  }
});

const ViewFeature = posed.div({
  init: {
    x: -40,
    y: 1,
    transition
  },
  hover: {
    x: 0,
    y: 1,
    transition
  }
});

export default ({ data }) => (
  <Layout>
    <SEO />
    <Helmet>
      <title>{`${config.siteTitle} | ${config.siteTitleAlt}`}</title>
    </Helmet>

    <div
      css={css`
        min-height: 45vh;
        margin: 30vw 0 15vw 0;
        padding: 6.5vw 9.333vw 0 9.333vw;
        @media (min-width: 768px) {
          width: calc(60% - 2vw);
          min-height: auto;
          position: relative;
          margin: 15vw 0;
        }
      `}
    >
      <Header
        title="Artisan Floral Studio"
        description="Operating out of a restored bespoke wedding venue 30 minutes from Tauranga. We do custom floral installations, bouquets and arrangements for weddings, events and special occasions."
      />
    </div>

    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 9.333vw;
        @media (min-width: 768px) {
          margin-top: 15vw;
          max-width: 915px;
          margin: 0 auto;
        }
      `}
    >
      {data.allMarkdownRemark.edges.map(({ node }, i) => (
        <article
          css={css`
            width: 100%;
            max-height: 90vh;
            position: relative;
            margin: 0 0 20vw 0;
            text-transform: uppercase;
          `}
          key={node.id}
        >
          <Link to={node.fields.slug}>
            <Feature>
              <div
                css={css`
                  overflow: hidden;
                `}
              >
                <ImgWrap>
                  <Img
                    style={{ maxHeight: "calc(90vh)" }}
                    imgStyle={{ objectFit: "cover" }}
                    alt={node.frontmatter.title}
                    fluid={node.frontmatter.cover.childImageSharp.fluid}
                  />
                </ImgWrap>
              </div>
              <div
                css={css`
                  @media (min-width: 768px) {
                    text-align: right;
                    -webkit-writing-mode: vertical-rl;
                    -ms-writing-mode: tb-rl;
                    writing-mode: vertical-rl;
                    text-orientation: mixed;
                    -webkit-transform: rotate(180deg);
                    transform: rotate(180deg);
                    display: flex;
                    align-items: flex-end;
                    position: absolute;
                    bottom: 0;
                    height: 100%;
                    text-transform: uppercase;
                    right: -2.25vw;
                    line-height: 2.275vw;
                    z-index: 3;
                    overflow: hidden;
                    /* border: 1px solid red; */
                    font-size: 0.675em;
                  }
                  @media (min-width: 1200px) {
                    font-size: 0.875em;
                  }
                `}
              >
                <Title
                  css={css`
                    @media (max-width: 767px) {
                      /* Stop pose animations on touch */
                      transform: none !important;
                    }
                    @media (min-width: 768px) {
                      margin: 0.5em 0;
                    }
                  `}
                >
                  <h2
                    css={css`
                      margin: 1em 0 0.25em 0;
                      @media (min-width: 768px) {
                        display: inline;
                        margin: 0 0 1em 0;
                      }
                    `}
                  >
                    {node.frontmatter.title}
                  </h2>
                  <span>{node.frontmatter.caption}</span>
                </Title>
                <ViewFeature
                  css={css`
                    display: none;
                    @media (min-width: 768px) {
                      display: flex;
                      position: absolute;
                      top: 0;
                      left: 0;
                      width: 100%;
                      height: 100%;
                      margin: 0;
                    }
                  `}
                >
                  <span>View Feature</span>
                </ViewFeature>
              </div>
            </Feature>
          </Link>
        </article>
      ))}
    </div>
  </Layout>
);

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { frontmatter: { feat: { eq: true } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            caption
            path
            date(formatString: "DD MMMM, YYYY")
            cover {
              childImageSharp {
                fluid(maxWidth: 915, quality: 70) {
                  aspectRatio
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
